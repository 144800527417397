<template>
    <v-dialog
        persistent
        v-model="cloneDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="700px"
        min-width="600px"
        max-width="800px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.cloneThisContract') }}</v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="cloneContractForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.originalContract') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :value="OriginalContract__title"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    readonly
                                    solo
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="Contract__Purchaseorder__id != null">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.copyPurchaseOrder') }}?</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-checkbox
                                    :true-value="1"
                                    autocomplete="off"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-if="Contract__salestype_id != 2"
                                    v-model="copyPurchaseOrder"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="Contract__Purchaseorder__id == null && Contract__salestype_id != 2" class="pt-3">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.copyPurchaseOrder') }}?</v-flex>
                        <v-flex xs7 lg7 md6><span class="red--text">{{ $t('message.noPurchaseOrderAvailable') }}</span></v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.newContractNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :background-color="[1,3,5].includes(Contract__salestype_id) ? 'grey lighten-3' : ''"
                                    :label="Contract__title ? '' : $t('message.required')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    :value="Contract__title"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    required
                                    solo
                                    @change="Contract__title = $event"
                            >
                                <template v-slot:append>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                    class="orange--text text--darken-3"
                                                    v-on="on"
                                                    @click="getNewContractNumber"
                                            >mdi-download</v-icon>
                                        </template>
                                        <span>{{ $t('message.getNewContractNumber') }}</span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesType') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="filteredSalestypes"
                                    :label="Contract__salestype_id ? '' : $t('message.required')"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Salestype.title"
                                    item-value="Salestype.id"
                                    solo
                                    v-model="Contract__salestype_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="false">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.revisionNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="revisionNumbers"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="Contract__revision_no"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="false">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.partialContract') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="partialContractNumbers"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="Contract__partial_no"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-menu
                                    ref="contractDateMenu"
                                    v-model="contractDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :label="Contract__contractdate ? '' : $t('message.required')"
                                        :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                        :value="computedContractDateFormatted"
                                        append-icon="event"
                                        background-color="white"
                                        class="force-text-left"
                                        clearable
                                        hide-details="auto"
                                        hint="DD/MM/YY format"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="Contract__contractdate = null"
                                        @click:append="contractDateMenu = true"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="Contract__contractdate"
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    show-week
                                    @input="contractDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items=allSuppliers
                                    :label="Contract__supplier_id ? '' : $t('message.required')"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Supplier.title"
                                    item-value="Supplier.id"
                                    required
                                    solo
                                    v-model="Contract__supplier_id"
                                    @change="supplierChanged"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.shippingWeek') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-combobox
                                    :items="sortedCalendarWeeks"
                                    :label="Contract__shippingweekstatement ? '' : $t('message.required')"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumbers]"
                                    :return-object="false"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Calendarweek.title"
                                    item-text="Calendarweek.title"
                                    solo
                                    v-model="Contract__shippingweekstatement"
                            >
                                <template v-slot:item="{item}">
                                    <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                        <div class="d-flex flex-column" style="width: 100% !important;">
                                            <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                            <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                        </div>
                                    </template>
                                    <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                        <template v-if="[52,53].includes(item.Calendarweek.id)">
                                            <div class="d-flex flex-column" style="width: 100% !important;">
                                                <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                                <v-divider></v-divider>
                                                <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                        </template>
                                    </template>
                                    <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                        <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                    </template>
                                </template>
                            </v-combobox>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.osd') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-menu
                                    ref="originalShippingDateMenu"
                                    v-model="originalShippingDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            :label="Contract__shipweekdate ? '' : $t('message.required')"
                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                            :value="computedOriginalShippingDateFormatted"
                                            append-icon="event"
                                            background-color="white"
                                            class="force-text-left"
                                            clearable
                                            hide-details="auto"
                                            hint="DD/MM/YY format"
                                            persistent-hint
                                            readonly
                                            solo
                                            v-on="on"
                                            @click:clear="Contract__shipweekdate = null"
                                            @click:append="originalShippingDateMenu = true"
                                    />
                                </template>
                                <v-date-picker
                                        v-model="Contract__shipweekdate"
                                        first-day-of-week="1"
                                        locale-first-day-of-year="4"
                                        show-week
                                        @input="originalShippingDateMenu = false"
                                />
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.incoterm') }}</v-flex>
                        <v-flex xs4 lg4 md3 py-1>
                            <v-select
                                    :items=incoterms
                                    :label="Contract__incoterm_id ? '' : $t('message.required')"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Incoterm.id"
                                    item-text="Incoterm.title"
                                    required
                                    solo
                                    v-model="Contract__incoterm_id"
                            />
                        </v-flex>
                        <v-flex xs3 lg3 md3 py-1 pl-0>
                            <v-select
                                    :items=incotermports
                                    :label="Contract__incotermport ? '' : $t('message.required')"
                                    :rules="[...validationRules.requiredWithZero]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="value"
                                    item-text="title"
                                    required
                                    solo
                                    v-model="Contract__incotermport"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.numberOfContainers') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-row>
                                <v-col cols="5" v-if="[4,7].includes(Contract__containersize_id) == false">
                                    <v-text-field
                                            :rules="[...validationRules.number, ...validationRules.minimumOne]"
                                            :value="Contract__containercount"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="Contract__containercount = $event"
                                    />
                                </v-col>
                                <v-col cols="1" class="pa-0 d-flex flex-row align-center justify-center" v-if="[4,7].includes(Contract__containersize_id) == false">
                                    <v-icon class="mt-2">mdi-close</v-icon>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                            :items="containerSizes"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            item-text="Containersize.title"
                                            item-value="Containersize.id"
                                            reverse
                                            solo
                                            v-model="Contract__containersize_id"
                                    />
                                </v-col>
                            </v-row>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.portOfLoading') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items=allShippingLoadingPorts
                                    :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                    :item-value="'Shippingport.id'"
                                    :label="Contract__loadingport_id ? '' : $t('message.required')"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    required
                                    solo
                                    v-model="Contract__loadingport_id"
                            >
                                <template v-slot:selection="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.portOfDischarge') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items=allShippingDischargePorts
                                    :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                    :item-value="'Shippingport.id'"
                                    :label="Contract__shippingport_id ? '' : $t('message.required')"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    required
                                    v-model="Contract__shippingport_id"
                            >
                                <template v-slot:selection="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0 pr-8">
                <v-btn color="default" small @click="cloneDialog = false" >{{$t('message.cancel')}}</v-btn>
                <v-btn
                        :loading="loading.clone"
                        color="info"
                        small
                        @click="addClonedContract"
                >{{ $t('message.proceed') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    // import Vue from 'vue'
    import {textTruncate, getCurrentAppLayout, formatDate, isObject} from "Helpers/helpers";
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    // import {mapFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'
    import {
        getCurrentWeek,
        getCurrentYear,
        getDateRangeOfWeek,
        getDateRangeOfWeekWithYear,
        getNextYear,
        getSafe,
        numberFormat,
        yearHasExtraWeek
    } from "Helpers/helpers";
    import router from "../../router";
    import {api} from "../../api";

    export default {
        name: "CloneContract",
        props: ['dialog','dialog-closed'],
        data(){
            return {
                clone_dialog: false,
                contractDateMenu: false,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                errors: {
                    missing_values: []
                },
                filteredSalestypes: [
                    {Salestype: {id: 1, title: 'Direct Sales', code: 'DIR'}},
                    {Salestype: {id: 2, title: 'Commission Sales', code: 'COM'}},
                    {Salestype: {id: 5, title: 'Serviced Sales', code: 'SS'}},
                ],
                filters: {
                    offices: {
                        salestype_id: null,
                        country_id: null,
                    },
                    banks: {
                        office_id: null,
                        currency_id: null
                    }
                },
                incotermports: [
                    {value: 0, title: 'POD'},
                    {value: 1, title: 'POL'},
                    {value: 2, title: this.$t('message.doNotShow')},
                ],
                loading: {
                    clone: false,
                    newContractNumber: false,
                },
                originalShippingDateFormatted: null,
                originalShippingDateMenu: false,
                rules: {
                    date: v => !!v || this.$t('message.required'),
                    incoterm: v => !!v || this.$t('message.required'),
                    incotermPort: v => typeof v === "number" || this.$t('message.required'),
                    number: v => !!v || this.$t('message.required'),
                    osd: v => !!v || this.$t('message.required'),
                    salesType: v => !!v || this.$t('message.required')
                },
                validForm: true
            }
        },
        computed: {
            ...mapFields('contract',{
                Contract__id: 'clone.Contract.id',
                Contract__bank_id: 'clone.Contract.bank_id',
                Contract__buyeraddresstype: 'Contract.buyeraddresstype',
                Contract__currency_id: 'clone.Contract.currency_id',
                Contract__commission_term_id: 'clone.Contract.commission_term_id',
                Contract__commission_term_against_id:'clone.Contract.commission_term_against_id',
                Contract__contractdate: 'clone.Contract.contractdate',
                Contract__containercount: 'clone.Contract.containercount',
                Contract__containersize_id: 'clone.Contract.containersize_id',
                Contract__customer_id: 'clone.Contract.customer_id',
                Contract__customer_pi_no: 'clone.Contract.customer_pi_no',
                Contract__date_signed: 'clone.Contract.date_signed',
                Contract__deliveryaddress_id: 'clone.Contract.deliveryaddress_id',
                Contract__destinatione_id: 'clone.Contract.destinatione_id',
                Contract__footer_notes: 'clone.Contract.footer_notes',
                Contract__fsc: 'clone.Contract.fsc',
                Contract__is_export: 'vContract.is_export',
                Contract__issigned: 'clone.Contract.issigned',
                Contract__isposigned: 'clone.Contract.isposigned',
                Contract__incoterm_id: 'clone.Contract.incoterm_id',
                Contract__incotermport: 'clone.Contract.incotermport',
                Contract__loadingport_id: 'clone.Contract.loadingport_id',
                Contract__marketsegment_id: 'clone.Contract.marketsegment_id',
                Contract__office_id: 'clone.Contract.office_id',
                Contract__partial_no: 'clone.Contract.partial_no',
                Contract__paymentstatement_id: 'clone.Contract.paymentstatement_id',
                Contract__paymentterm_id: 'clone.Contract.paymentterm_id',
                Contract__productgroup_id: 'clone.Contract.productgroup_id',
                Contract__productorigin_id: 'clone.Contract.productorigin_id',
                Contract__po_date_signed: 'clone.Contract.purchaseorder.date_signed',
                Contract__revision_no: 'clone.Contract.revision_no',
                Contract__salescontact_id: 'clone.Contract.salescontact_id',
                Contract__salestype_id: 'clone.Contract.salestype_id',
                Contract__sellertitle: 'clone.Contract.sellertitle',
                Contract__shippingport_id: 'clone.Contract.shippingport_id',
                Contract__shipweekdate: 'clone.Contract.shipweekdate',
                Contract__shippingweekstatement: 'clone.Contract.shippingweekstatement',
                Contract__showbank: 'clone.Contract.showbank',
                Contract__showsignature: 'clone.Contract.showsignature',
                Contract__stampsignatured: 'clone.Contract.stampsignatured',
                Contract__supplier_id: 'clone.Contract.supplier_id',
                Contract__supplier_pi_no: 'clone.Contract.supplier_pi_no',
                Contract__title: 'clone.Contract.title',
                Contract__Purchaseorder__id: 'clone.Contract.Purchaseorder.id',
                OriginalContract__id: 'clone.OriginalContract.id',
                OriginalContract__title: 'clone.OriginalContract.title',
                copyPurchaseOrder: 'clone.copyPurchaseOrder'
            }),
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('shippingport', {
                allShippingLoadingPorts: 'allShippingLoadingPorts',
                allShippingDischargePorts: 'allShippingDischargePorts'
            }),
            ...mapGetters('supplier', {
                allSuppliers: 'allSuppliers',
                findSupplierById: 'findSupplierById'
            }),
            ...mapGetters([
                'buyerContractOffices',
                'calendarWeeks',
                'certifications',
                'containerSizes',
                'incoterms',
                'offices',
                'officeCountries',
                'validationRules'
            ]),
            cloneDialog: {
                get() {
                    return this.clone_dialog;
                },
                set(value){
                    this.clone_dialog = value
                    if(value == false) {
                        this.$emit('dialog-closed')
                    }
                }
            },
            computedContractDateFormatted () {
                return formatDate(this.Contract__contractdate)
            },
            computedOriginalShippingDateFormatted () {
                return formatDate(this.Contract__shipweekdate)
            },
            partialContractNumbers: function () {
                let items = []
                items.push({'text': this.$t('message.no'), 'value': null})
                let i
                for (i = 1; i <= 10; i++) {
                    let obj = {}
                    obj['text'] = '- ' + i
                    obj['value'] = i.toString()
                    items.push(obj)
                }
                return items
            },
            revisionNumbers: function () {
                let items = []
                items.push({'text': this.$t('message.none'), 'value': null})
                let i
                for (i = 1; i <= 10; i++) {
                    let obj = {}
                    obj['text'] = 'Rev' + i.toString()
                    obj['value'] = 'Rev' + i.toString()
                    items.push(obj)
                }
                return items
            },
            sortedCalendarWeeks: function () {
                const currentWeek = getCurrentWeek();
                let nextWeeks = []
                let previousWeeks = []
                this.calendarWeeks.forEach(cw => {
                    if(cw.Calendarweek.id >= currentWeek){
                        nextWeeks.push(cw)
                    } else {
                        previousWeeks.push(cw)
                    }
                })

                //check if current year has week 53
                if(yearHasExtraWeek(getCurrentYear)){
                    const calendarObj = {Calendarweek: {id:53, title: 'WK 53', dateRange: ''}}
                    nextWeeks.push(calendarObj)
                }

                //generate date range values
                nextWeeks.forEach((item, idx) => {
                    nextWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeek(item.Calendarweek.id)
                })
                previousWeeks.forEach((item, idx) => {
                    previousWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeekWithYear(item.Calendarweek.id, getNextYear())
                })
                const weeks = [...nextWeeks, ...previousWeeks]
                return weeks
            },
        },
        methods: {
            ...mapActions('contract', {
                contractBuyerChanged: 'contractBuyerChanged',
                createClonedContract: 'createClonedContract',
                resetAllContracts: 'resetAllContracts',
            }),
            addClonedContract(){
                if(this.$refs.cloneContractForm.validate()) {
                    this.loading.clone = true
                    this.createClonedContract()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.contractCloned'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.clone = false
                                this.resetAllContracts().then(() => {
                                    // push new route
                                    router.push({
                                        name: 'update_contract',
                                        params: {contractId: response.contract_id}
                                    }).catch(err => {
                                    })
                                })
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.contractNotCloned'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.contractNotCloned'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.clone = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.contractNotCloned'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.clone = false
                        })
                        .finally(() => {
                            this.loading.clone = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.contractNotCloned'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            },
            getCurrentWeek,
            getCurrentYear,
            getNextYear,
            getDateRangeOfWeek,
            getDateRangeOfWeekWithYear,
            getNewContractNumber: function() {
                let contract_prefix = this.buyerContractOffices.find(d => d.salestype_id == this.filters.offices.salestype_id && d.country_id == this.filters.offices.country_id && d.office_id == this.Contract__office_id)?.contract_prefix
                if(this.Contract__customer_id != null && this.Contract__salestype_id != null && this.Contract__office_id != null && this.Contract__supplier_id != null) {
                    this.loading.newContractNumber = true
                    return new Promise ((resolve, reject) => {
                        api
                            .get("/contracts/number",{
                                params: {
                                    contract_prefix: contract_prefix,
                                    destinatione_id: this.Contract__destinatione_id,
                                    office_country_id: this.offices.find(office=>office.Office.country_id == this.Contract__office_id)?.Office?.country_id,
                                    productorigin_id: this.Contract__productorigin_id,
                                    salescontact_id: this.Contract__salescontact_id,
                                    salestype_id: this.Contract__salestype_id,
                                }
                            })
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.Contract__title = response.data.data
                                } else if(response.data.status == 'error'){
                                    this.enterContractNumberManually = true
                                }
                                this.loading.newContractNumber = false
                            })
                            .catch(error => {
                                reject(error);
                                this.$toast.error(this.$t('message.errors.noNewContractNo'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.newContractNumber = false
                            })
                            .finally(()=>{
                                this.loading.newContractNumber = false
                            })
                    })
                } else {
                    this.errors.missing_values = [] //empty errors first
                    if(this.Contract__customer_id == null){
                        this.errors.missing_values.push(this.$t('message.buyer'));
                    }
                    if(this.Contract__salestype_id == null){
                        this.errors.missing_values.push(this.$t('message.salesType'));
                    }
                    if(this.Contract__office_id == null){
                        this.errors.missing_values.push(this.$t('message.appTimberCompany'));
                    }
                    if(this.Contract__supplier_id == null){
                        this.errors.missing_values.push(this.$t('message.supplier'));
                    }
                    this.dialogs.error_message = this.$t('message.errors.cannotGetNewContractNumber');
                    this.dialogs.error = true;
                }
            },
            supplierChanged () {
                let supplier = this.findSupplierById(this.Contract__supplier_id)
                if(supplier != null){
                    this.Contract__productorigin_id = supplier.Country.id
                    // Deactivate for the meantime
                    // this.Contract__title = null
                }
            },
        },
        watch: {
            dialog(value) {
                this.clone_dialog = value
            }
        },
        created(){
            this.filters.offices.salestype_id = this.Contract__salestype_id
            if(this.Contract__customer_id) {
                let buyer = this.findBuyerById(this.Contract__customer_id)
                if (this.officeCountries.includes(buyer?.Country?.id)) {
                    this.filters.offices.country_id = buyer?.Country?.id
                } else {
                    this.filters.offices.country_id = 999
                }
            }
        }
    }
</script>

<style scoped>
.v-card__text {
    color: unset !important;
    font-size: unset !important;
}
</style>